<template>
  <div class="container">
    <div class="profile-header"><h1>User Profile</h1></div>
    <div class="profile-content">
      <div class="row">
        <div class="col-md-8">
          <div class="profile-info"><h3>Hi, {{ user.signInDetails.loginId }} 👋</h3>
            <p><strong>User ID:</strong> {{ user.userId }}</p>
            <div class="profile-actions">
              <button class="btn btn-info change-password-btn" @click="changePassword">Change Password</button>
              <button class="btn btn-danger logout-btn" @click="logout">Log Out</button>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-12">
          <div class="user-metadata"><h3>User Metadata</h3>
            <pre>{{ user }}</pre>
          </div>
        </div>
      </div>
      <div class="jwt-tokens"><h2>JWT Tokens</h2>
        <button class="btn btn-secondary token-btn" @click="toggleJwtTokens">
          {{ showJwtTokens ? 'Hide JWT Tokens' : 'Show JWT Tokens' }}
        </button>
        <div v-if="showJwtTokens">
          <div v-if="idToken" class="token-section">
            <h3>ID Token</h3>
            <h4>Raw</h4>
            <div class="token-container">
              <pre class="token-raw">{{ idToken }}</pre>
              <button class="copy-btn" @click="copyToClipboard($event, idToken)">Copy</button>
            </div>
            <h4>Decoded</h4>
            <div class="token-container">
              <pre class="token-decoded">{{ JSON.stringify(decodedIdToken, null, 2) }}</pre>
              <button class="copy-btn" @click="copyToClipboard($event, JSON.stringify(decodedIdToken, null, 2))">Copy</button>
            </div>
          </div>
          <div v-if="accessToken" class="token-section">
            <h3>Access Token</h3>
            <h4>Raw</h4>
            <div class="token-container">
              <pre class="token-raw">{{ accessToken }}</pre>
              <button class="copy-btn" @click="copyToClipboard($event, accessToken)">Copy</button>
            </div>
            <h4>Decoded</h4>
            <div class="token-container">
              <pre class="token-decoded">{{ JSON.stringify(decodedAccessToken, null, 2) }}</pre>
              <button class="copy-btn" @click="copyToClipboard($event, JSON.stringify(decodedAccessToken, null, 2))">Copy</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      showJwtTokens: false,
    };
  },
  computed: {
    ...mapState('auth', ['user', 'idToken', 'accessToken', 'decodedIdToken', 'decodedAccessToken']),
  },
  methods: {
    ...mapActions('auth', ['logout', 'fetchAuthKeys']),
    toggleJwtTokens() {
      if (!this.showJwtTokens) {
        this.fetchAuthKeys();
      }
      this.showJwtTokens = !this.showJwtTokens;
    },
    async logout() {
      this.$router.push('/');
      this.$store.dispatch('auth/logout');
    },
    changePassword() {
      this.$router.push({name: 'ChangePassword'});
    },
    async copyToClipboard(event, text) {
      try {
        await navigator.clipboard.writeText(text);
        const button = event.target;
        button.classList.add('copied');
        button.textContent = 'Copied!';
        setTimeout(() => {
          button.classList.remove('copied');
          button.textContent = 'Copy';
        }, 2000);
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    },
  },
};
</script>

<style scoped>
.profile-header {
  text-align: center;
  margin-bottom: 30px;
}

.profile-content {
  background-color: #f8f9fa;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.profile-info {
  margin-bottom: 30px;
}

.profile-actions {
  margin-top: 20px;
}

.btn {
  margin-right: 10px;
}

.user-metadata {
  margin-top: 30px;
}

.user-metadata h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.jwt-tokens {
  margin-top: 30px;
}

.token-btn {
  margin-bottom: 20px;
}

.token-section {
  margin-bottom: 20px;
}

.token-section h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.token-section h4 {
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 5px;
}

pre {
  background-color: #2d2d2d;
  color: #f8f8f2;
  padding: 15px;
  border-radius: 5px;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.token-raw, .token-decoded {
  max-height: 300px;
  overflow-y: auto;
}

/* Custom scrollbar for WebKit browsers */
pre::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

pre::-webkit-scrollbar-track {
  background: #3e3e3e;
}

pre::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

pre::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.token-container {
  position: relative;
  margin-bottom: 20px;
}

.copy-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(76, 175, 80, 0.8);
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s ease;
}

.copy-btn:hover {
  background-color: rgba(76, 175, 80, 1);
}

.copy-btn.copied {
  background-color: #3f51b5;
  animation: pulse 0.5s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>