import {getCurrentInstance} from "vue";
import {toast} from "vue3-toastify";
import 'vue3-toastify/dist/index.css';
import apiClient from "@/axios";
import axios from "axios";

const API_PATHS = {
    FILES: 'files',
    UPLOAD_REQUEST: 'files/upload_request',
    UPLOAD_MULTIPART_REQUEST: 'files/upload_multipart_request',
    COMPLETE_MULTIPART_UPLOAD: 'files/complete_multipart_upload',
};

const HEADERS = {
    CONTENT_TYPE: 'Content-Type',
};

class Semaphore {
    constructor(maxConcurrency) {
        this.maxConcurrency = maxConcurrency;
        this.currentConcurrency = 0;
        this.queue = [];
    }

    async wait() {
        if (this.currentConcurrency < this.maxConcurrency) {
            this.currentConcurrency++;
            return;
        }

        await new Promise((resolve) => this.queue.push(resolve));
    }

    signal() {
        this.currentConcurrency--;

        if (this.queue.length > 0) {
            const nextResolve = this.queue.shift();
            nextResolve();
        }
    }
}

async function uploadPartWithSemaphore(commit, uploadSemaphore, part, chunk, onUploadProgress, cancelToken) {
    await uploadSemaphore.wait();
    try {
        console.log(`Starting upload of part ${part.part_number}`);
        const start = new Date().getTime();

        const config = {
            onUploadProgress: (progressEvent) => {
                if (onUploadProgress) {
                    onUploadProgress(progressEvent, part);
                }
            },
            withCredentials: false,
            cancelToken: cancelToken,
        };

        const axiosS3Instance = axios.create();
        let response = await axiosS3Instance.put(part.upload_presigned_url, chunk, config);

        if (response.status === 200) {
            const end = new Date().getTime();
            console.log(`Finished upload of part ${part.part_number} in ${(end - start) / 1000} seconds`);
            const etag = response.headers['etag'];
            console.log("Complete with part... etag: " + etag);
            return {PartNumber: part.part_number, ETag: etag};
        } else {
            console.error("Upload failed. Status code: " + response.status);
        }
    } catch (error) {
        console.log(`error... Part: ${part.part_number}`);
        throw error;
    } finally {
        uploadSemaphore.signal();
    }
}

const state = {
    files: null,
    file: null,
    uploadProgress: {
        totalBytes: 0,
        uploadedBytes: 0,
        percentage: 0
    },
    chunkProgressList: [],
    fileError: null,
    isLoading: false,
};

const getters = {
    stateFiles: state => state.files,
    stateFile: state => state.file,
    stateFileError: state => state.fileError,
    stateIsLoading: state => state.isLoading,
    stateUploadProgress: state => state.uploadProgress,
    stateChunkProgressList: state => state.chunkProgressList,
};

const actions = {
    async getFiles({ commit }) {
        try {
            const response = await apiClient.get(API_PATHS.FILES);
            commit('setFiles', response.data);
        } catch (error) {
            console.error('Error fetching files:', error);
            throw error;
        }
    },

    async getFile({commit}, id) {
        console.log("get file data: " + id);
        commit('setLoading', true);
        try {
            let file = await apiClient.get(`${API_PATHS.FILES}/${id}`);
            console.log("get file data: " + JSON.stringify(file.data));
            commit('setFile', file.data);
        } catch (error) {
            console.log(error);
            let errorMessage = "Failed to load file.";
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error;
            }
            commit('setFileError', errorMessage);
            commit('setLoading', false);
        } finally {
            commit('setLoading', false);
        }
    },

    async clearFile({commit}) {
        commit('setFile', null);
    },

    async uploadFile({commit}, {file, onUploadProgress, expires, cancelToken}) {
        console.log("Call to uploadFile with file: " + JSON.stringify(file.name));

        const multipartThreshold = 100 * 1024 * 1024; // 100 MB

        if (file.size >= multipartThreshold) {
            // Multipart upload flow
            let upload_request = {
                "name": file.name,
                "type": file.type,
                "desc": "add desc here from JS",
                "expires": expires.toISOString(),
                "content_type": file.type,
                "size_bytes": file.size,
            };
            let file_upload_request_resp = null;
            try {
                file_upload_request_resp = await apiClient.post(API_PATHS.UPLOAD_MULTIPART_REQUEST, upload_request);
            } catch (error) {
                console.log(error);
                if (error.response == null) {
                    throw new Error(error.toString());
                }
                if (error.response.status === 400) {
                    throw new Error(error.response.data['error']);
                }
                if (error.response.status === 413) {
                    throw new Error("File too large");
                } else {
                    throw new Error(error.response.data['error']);
                }
            }

            console.log("file_upload_request_resp.data: " + JSON.stringify(file_upload_request_resp.data));
            console.log("file_upload_request_resp.data.file_id: " + file_upload_request_resp.data['file_id']);
            console.log("content-type: " + file.type);
            console.log("number of parts: " + file_upload_request_resp.data['parts'].length);

            const maxConcurrency = 4;
            const semaphore = new Semaphore(maxConcurrency);
            const uploadPromises = [];
            let startByte = 0;
            let endByte = 0;

            commit('initChunkUploadProgress', file_upload_request_resp.data['parts'].length);
            commit('initUploadProgress', file.size);

            for (let i = 0; i < file_upload_request_resp.data['parts'].length; i++) {
                let part = file_upload_request_resp.data['parts'][i];
                endByte = startByte + part['size_bytes'];
                const chunk = file.slice(startByte, endByte);
                uploadPromises.push(uploadPartWithSemaphore(commit, semaphore, part, chunk, (progressEvent) => {
                    commit('setChunkUploadProgress', {
                        part: part.part_number,
                        progress: parseInt((progressEvent.loaded) * 100 / progressEvent.total)
                    });
                }, cancelToken));
                startByte = endByte;
            }

            let uploadedParts = null;
            try {
                uploadedParts = await Promise.all(uploadPromises);
                console.log('Uploaded Parts:', uploadedParts);
            } catch (error) {
                console.error('Error uploading parts:', error);
                throw error;
            }

            const complete_request = {
                'file_id': file_upload_request_resp.data['file_id'],
                'upload_id': file_upload_request_resp.data['upload_id'],
                'parts': uploadedParts
            };

            let complete_multipart_upload_resp = null;
            try {
                complete_multipart_upload_resp = await apiClient.post(API_PATHS.COMPLETE_MULTIPART_UPLOAD, complete_request);
            } catch (error) {
                console.error('Error completing multipart: ', error);
                throw error;
            }

            if (complete_multipart_upload_resp.status === 200) {
                console.log("success!!!!");
                return file_upload_request_resp.data['file_id'];
            }
        } else {
            // Single-threaded upload flow
            let upload_request = {
                "name": file.name,
                "type": file.type,
                "desc": "add desc here from JS",
                "expires": expires.toISOString(),
                "content_type": file.type,
                "size_bytes": file.size,
            };

            let file_upload_request_resp = null;
            try {
                file_upload_request_resp = await apiClient.post(API_PATHS.UPLOAD_REQUEST, upload_request);
            } catch (error) {
                console.log(error);
                if (error.response == null) {
                    throw new Error(error.toString());
                }
                if (error.response.status === 400) {
                    throw new Error(error.response.data['error']);
                }
                if (error.response.status === 413) {
                    throw new Error("File too large");
                } else {
                    throw new Error(error.response.data['error']);
                }
            }

            console.log("file_upload_request_resp.data: " + JSON.stringify(file_upload_request_resp.data));
            console.log("file_upload_request_resp.data.file_is: " + file_upload_request_resp.data['file_id']);
            console.log("content-type: " + file.type);

            let upload_url = file_upload_request_resp.data['upload_presigned_url'];

            commit('initUploadProgress', file.size);
            const config = {
                onUploadProgress: (progressEvent) => {
                    commit('updateUploadProgress', progressEvent.loaded);
                    if (onUploadProgress) {
                        onUploadProgress(progressEvent);
                    }
                },
                headers: {
                    [HEADERS.CONTENT_TYPE]: file.type
                },
                withCredentials: false,
                cancelToken: cancelToken,
            };

            const axiosS3instance = axios.create();
            let response = await axiosS3instance.put(upload_url, file, config);

            console.log("Upload Complete");
            console.log("response: " + JSON.stringify(response));
            return file_upload_request_resp.data['file_id'];
        }
    },

    async clearProgress({commit}) {
        commit('initChunkUploadProgress', []);
    },

    // eslint-disable-next-line no-unused-vars
    async deleteFile({commit}, id) {
        console.log("Call to delete with file: " + id);
        let file_delete_resp = null;
        try {
            file_delete_resp = await apiClient.delete(`${API_PATHS.FILES}/${id}`);
            toast.success('File deleted successfully');
        } catch (error) {
            console.log(error);
            if (error.response == null) {
                throw new Error(error.toString());
            }
            if (error.response.status === 400) {
                throw new Error(error.response.data['error']);
            } else {
                throw new Error(error.response.data['error']);
            }
        }
        console.log("file_upload_request_resp.data: " + JSON.stringify(file_delete_resp.data));
        return true;
    },

    // eslint-disable-next-line no-unused-vars
    async shareFileByEmail({commit}, {file_id, email}) {
        try {
            const payload = {email: email};
            await apiClient.post(`/${API_PATHS.FILES}/${file_id}/share`, payload);
            toast.success(`File shared with ${email}`);
        } catch (error) {
            console.error('Error sharing file via email:', error);
            toast.error(`Error sharing file with ${email}: ${error.response.data.detail}`);
        }
    },

    // eslint-disable-next-line no-unused-vars
    showErrorToast({commit}, message) {
        const vueInstance = getCurrentInstance();
        if (vueInstance) {
            vueInstance.appContext.config.globalProperties.$toast.error(message);
        }
    }
};

const mutations = {
    setFiles(state, files) {
        state.files = files;
    },
    setFile(state, file) {
        state.file = file;
    },
    initUploadProgress(state, totalBytes) {
        state.uploadProgress.totalBytes = totalBytes;
        state.uploadProgress.uploadedBytes = 0;
        state.uploadProgress.percentage = 0;
    },
    updateUploadProgress(state, uploadedBytes) {
        state.uploadProgress.uploadedBytes = uploadedBytes;
        state.uploadProgress.percentage = Math.round((uploadedBytes / state.uploadProgress.totalBytes) * 100);
    },
    initChunkUploadProgress(state, parts) {
        state.chunkProgressList = Array(parts).fill(0);
    },
    setChunkUploadProgress(state, {part, progress}) {
        const updatedProgressList = [...state.chunkProgressList];
        updatedProgressList[part - 1] = progress;
        state.chunkProgressList = updatedProgressList;

        // Calculate overall progress percentage
        const totalProgress = updatedProgressList.reduce((sum, partProgress) => sum + partProgress, 0);
        state.uploadProgress.percentage = Math.round(totalProgress / updatedProgressList.length);
    },
    setFileError(state, errorMessage) {
        state.fileError = errorMessage;
    },
    setLoading(state, isLoading) {
        state.isLoading = isLoading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};