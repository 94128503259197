import {createRouter, createWebHistory} from 'vue-router';
import AboutView from '@/views/AboutView.vue';
import TagsView from '@/views/TagsView.vue';
import TagDetailView from "@/views/TagDetailView.vue";
import TagPublicView from "@/views/TagPublicView.vue";
import TagEditView from "@/views/TagEditView.vue";
import FileUploadView from "@/views/FileUploadView.vue";
import FileDetailView from "@/views/FileDetailView.vue";
import AdminView from "@/views/AdminView.vue";
import HomeView_new from "@/views/HomeView.vue";
import ProfileView from "@/views/ProfileView.vue";
import NotAuthorizedView from "@/views/NotAuthorizedView.vue";
import LoadingView from "@/views/LoadingView.vue";
import LoginView from "@/views/LoginView.vue";
import FourZeroFourView from "@/views/FourZeroFourView.vue";
import TermsOfServiceView from "@/views/TermsOfServiceView.vue";
import store from '@/store';
import ChangePasswordView from "@/views/ChangePasswordView.vue";
import PasswordResetVue from "@/views/ForcedPasswordResetView.vue";
import ConfirmSignupCodeView from "@/views/ConfirmSignupCodeView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";

const routes = [
    {
        path: '/',
        name: "Home",
        component: HomeView_new,
    },
    {
        path: '/profile',
        name: "Profile",
        component: ProfileView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: "Login",
        component: LoginView,
    },
    {
        path: '/change-password',
        name: "ChangePassword",
        component: ChangePasswordView,
    },
    {
        path: '/password-reset/:resetMode',
        name: "PasswordReset",
        component: PasswordResetVue,
        props: true
    },
    {
        path: '/forget-password',
        name: "ForgetPassword",
        component: ForgotPasswordView,
    },
    {
        path: '/confirm-signup',
        name: "ConfirmSignup",
        component: ConfirmSignupCodeView,
    },
    {
        path: '/about',
        name: "About",
        component: AboutView,
    },
    {
        path: '/admin',
        name: "Admin",
        component: AdminView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/tags',
        name: "Tags",
        component: TagsView,
    },
    {
        path: '/tags/:id',
        name: "TagsDetails",
        component: TagDetailView,
    },
    {
        path: '/tags/:id/edit',
        name: "TagEdit",
        component: TagEditView,
    },
    {
        path: '/tags/new',
        name: "TagNew",
        component: TagEditView,
    },
    {
        path: '/t/:id',
        name: "TagPublicShortLink",
        component: TagPublicView,
    },
    {
        path: '/files',
        name: "FileUpload",
        component: FileUploadView,
    },
    {
        path: '/files/:id',
        name: "FileDetails",
        component: FileDetailView,
    },
    {
        path: '/loading',
        name: "Loading",
        component: LoadingView,
    },
    {
        path: '/terms-of-service',
        name: "TermsOfService",
        component: TermsOfServiceView
    },
    {
        path: '/not-authorized',
        name: "NotAuthorized",
        component: NotAuthorizedView,
    },
    {
        path: '/not-found',
        name: "FourZeroFour",
        component: FourZeroFourView,
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: {name: 'FourZeroFour'}
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters['auth/isAuthenticated'];
    const isLoading = store.getters['auth/isLoading'];
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    if (requiresAuth) {
        if (isLoading) {
            // Authentication is still loading, redirect to the loading page with the intended route as a query parameter
            next({name: 'Loading', query: {redirect: to.fullPath}});
        } else {
            // Authentication has finished loading
            if (isAuthenticated) {
                next();
            } else {
                // User is not authenticated, redirect to the login page with the intended route as a query parameter
                next({name: 'Login', query: {redirect: to.fullPath}});
            }
        }
    } else {
        // No authentication required
        next();
    }
});

export default router