<script>
import { defineComponent } from 'vue'
import FileUploadComponent from "@/components/FileUploadComponent.vue";
import FileListTable from "@/components/FileListTable.vue";
import { mapGetters, mapState } from 'vuex';
import LoadingSpinnerComponent from "@/components/LoadingSpinnerComponent.vue";

export default defineComponent({
  name: "FileUploadView",
  components: {
    FileUploadComponent,
    FileListTable,
    LoadingSpinnerComponent
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapState('auth', ['loading']),
    isLoading() {
      return this.loading;
    }
  },
  data() {
    return {
      form: {
        title: '',
        content: '',
      },
      errors: [],
    };
  },
});
</script>

<template>
  <section>
    <LoadingSpinnerComponent v-if="isLoading" />
    <div v-else>
      <h1>Upload a file</h1>
      <hr/>
      <p>Select a file to upload. All types are permitted.</p>
      <br>
      <FileUploadComponent/>
      <br>

      <!-- New section for displaying user's files -->
      <div v-if="isAuthenticated">
        <h2>Your Files</h2>
        <FileListTable :isAdmin="false" />
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>