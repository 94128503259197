<script>
import { defineComponent } from 'vue'
import FileListTable from "@/components/FileListTable.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "AdminView",
  components: {
    FileListTable,
  },
  computed: {
    ...mapGetters('auth', ['isLoading']),
  },
  data() {
    return {
      form: {
        title: '',
        content: '',
      },
      errors: [],
    };
  },
});
</script>

<template>
  <div id="Admin outer div">
    <section>
      <h1>Administrative Dashboard</h1>
      <hr/>
      <br>
      <FileListTable :isAdmin="true" />
    </section>
  </div>
</template>

<style scoped>

</style>