<template>
  <div class="password-reset-page">
    <div class="password-reset-container">
      <h2>{{ resetMode === 'forced' ? 'You are required to set a new password' : 'Set New Password' }}</h2>
      <form @submit.prevent="resetPasswordHandler">
        <div class="form-group">
          <label for="new-password">New Password:</label>
          <input
              type="password"
              id="new-password"
              v-model="newPassword"
              required
              :disabled="loading"
          />
        </div>
        <div class="form-group">
          <label for="confirm-password">Confirm New Password:</label>
          <input
              type="password"
              id="confirm-password"
              v-model="confirmPassword"
              required
              :disabled="loading"
          />
        </div>
        <button type="submit" :disabled="loading" class="reset-button">
          <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
          ></span>
          {{ loading ? 'Resetting...' : resetButtonText }}
        </button>
      </form>
      <p v-if="error" class="error-message">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {getCurrentUser} from "aws-amplify/auth";

export default {
  props: {
    resetMode: {
      type: String,
      required: true,
      validator: (value) => ['forced', 'magic'].includes(value),
    },
  },
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['getErrorMessage', 'isLoading']),
    error() {
      return this.getErrorMessage;
    },
    loading() {
      return this.isLoading;
    },
    resetButtonText() {
      return this.resetMode === 'forced' ? 'Set new password' : 'Reset password';
    },
  },
  methods: {
    ...mapActions('auth', ['resetPassword', 'fetchSession']),
    async resetPasswordHandler() {
      if (this.newPassword !== this.confirmPassword) {
        this.$store.commit('auth/SET_ERROR', 'New password and confirm password do not match');
        return;
      }

      try {
        const result = await this.resetPassword({
          newPassword: this.newPassword,
          resetMode: this.resetMode,
          magicLink: this.$route.query.magicLink || null,
        });
        if (result) {
          console.log('Password reset successful - redirecting to profile');
          //fetch user session
          const user = await getCurrentUser();
          await this.fetchSession(user);
          this.$router.push('/profile');
        }
      } catch (error) {
        console.error('Password reset error:', error);
      }
    },
  },
};
</script>

<style scoped>

.form-group {
  position: relative;
  margin-bottom: 30px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.terms-group input[type="checkbox"] {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid #ccc;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.terms-group input[type="checkbox"]:checked {
  background-color: #007bff;
  border-color: #007bff;
}

.terms-group input[type="checkbox"]:checked::before {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 16px;
}

.terms-group label {
  font-size: 14px;
  font-weight: normal;
  color: #555;
  cursor: pointer;
  transition: color 0.3s ease;
}

.terms-group label:hover {
  color: #007bff;
}
</style>