<template>
  <div class="file-list-table">
    <div v-if="error" class="alert alert-danger">
      {{ error }}
    </div>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead class="thead-light">
          <tr>
            <th>File</th>
            <th class="d-none d-md-table-cell">Size</th>
            <th class="d-none d-lg-table-cell">Uploaded</th>
            <th class="d-none d-lg-table-cell">Expires</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="5" class="text-center">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </td>
          </tr>
          <tr v-else-if="files.length === 0">
            <td colspan="5" class="text-center">No files available.</td>
          </tr>
          <tr v-for="file in files" :key="file.file_id" v-else>
            <td>
              <div class="d-flex align-items-center">
                <i :class="getFileIcon(file.content_type)" class="file-icon mr-2"></i>
                <div>
                  <div class="file-name" :title="file.name">{{ file.name }}</div>
                  <small class="text-muted d-md-none">{{ formatFileSize(file.size_bytes) }}</small>
                </div>
              </div>
            </td>
            <td class="d-none d-md-table-cell">{{ formatFileSize(file.size_bytes) }}</td>
            <td class="d-none d-lg-table-cell">{{ formatTimestamp(file.uploaded) }}</td>
            <td class="d-none d-lg-table-cell">{{ formatTimestamp(file.expires) }}</td>
            <td>
              <div class="btn-group">
                <router-link :to="{ name: 'FileDetails', params: { id: file.file_id } }" class="btn btn-sm btn-outline-primary">
                  <i class="bi bi-eye"></i> <span class="d-none d-md-inline">View</span>
                </router-link>
                <button v-if="isAdmin" class="btn btn-sm btn-outline-danger" @click="confirmDelete(file.file_id)">
                  <i class="bi bi-trash"></i> <span class="d-none d-md-inline">Delete</span>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Confirmation Modal -->
    <div v-if="isAdmin" id="deleteModal" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirm Deletion</h5>
            <button type="button" class="close" @click="closeConfirmationModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Are you sure you want to delete this file?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeConfirmationModal">Cancel</button>
            <button type="button" class="btn btn-danger" @click="deleteFileConfirmed">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Modal } from 'bootstrap';

export default defineComponent({
  name: 'FileListTable',
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modal: null,
      selectedFileId: null,
    };
  },
  computed: {
    ...mapGetters('files', ['stateFiles', 'stateIsLoading', 'stateFileError']),
    files() {
      return this.stateFiles || [];
    },
    isLoading() {
      return this.stateIsLoading;
    },
    error() {
      return this.stateFileError;
    },
  },
  created() {
    this.fetchFiles();
  },
  methods: {
    ...mapActions('files', ['getFiles', 'deleteFile']),
    fetchFiles() {
      this.getFiles();
    },
    formatFileSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    },
    getFileIcon(contentType) {
      if (contentType.startsWith('image/')) return 'bi bi-file-image';
      if (contentType.startsWith('video/')) return 'bi bi-file-play';
      if (contentType.startsWith('audio/')) return 'bi bi-file-music';
      if (contentType.includes('pdf')) return 'bi bi-file-pdf';
      if (contentType.includes('word')) return 'bi bi-file-word';
      if (contentType.includes('excel') || contentType.includes('spreadsheet')) return 'bi bi-file-excel';
      if (contentType.includes('powerpoint') || contentType.includes('presentation')) return 'bi bi-file-ppt';
      return 'bi bi-file-earmark';
    },
    confirmDelete(fileId) {
      this.selectedFileId = fileId;
      this.modal = new Modal(document.getElementById('deleteModal'));
      this.modal.show();
    },
    closeConfirmationModal() {
      this.modal.hide();
    },
    async deleteFileConfirmed() {
      try {
        await this.deleteFile(this.selectedFileId);
        this.fetchFiles();
      } catch (error) {
        console.error('Error deleting file:', error);
      }
      this.closeConfirmationModal();
    },
  },
});
</script>

<style scoped>
.file-list-table {
  margin-bottom: 2rem;
}

.table {
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 123, 255, 0.075);
  transition: background-color 0.3s ease;
}

.file-name {
  font-weight: 500;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-icon {
  font-size: 1.25rem;
  margin-right: 0.5rem;
}

.btn-group {
  white-space: nowrap;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

@media (max-width: 767.98px) {
  .table td {
    padding: 0.75rem 0.5rem;
  }
  
  .btn-group {
    display: flex;
    flex-direction: column;
  }
  
  .btn-group .btn {
    margin-bottom: 0.25rem;
  }
}
</style>