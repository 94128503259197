<template>
  <header>
    <nav class="navbar navbar-expand-md">
      <div class="container">
        <a class="navbar-brand" @click="goto_home">
          <img :src="logo" alt="Linker Logo" class="logo"/>
          <span class="website-name">Linker.To</span>
        </a>
        <button class="navbar-toggler" type="button" @click="isCollapsed = !isCollapsed">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div :class="{ 'collapse navbar-collapse': true, 'show': !isCollapsed }">
          <ul class="navbar-nav mx-auto">
            <li class="nav-item">
              <router-link class="nav-link" to="/" exact>Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/tags">Tags</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/files">Files</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/about">About</router-link>
            </li>
            <li class="nav-item">
              <a :href="API_Docs_URL" class="nav-link" target="_blank">API</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://status.linker.to" target="_blank">Status</a>
            </li>
          </ul>
          <div class="navbar-buttons">
            <div v-if="loading" class="spinner-container">
              <div class="spinner"></div>
            </div>
            <template v-else>
              <router-link v-if="!isAuthenticated" class="btn btn-primary" to="/login">Login/Signup</router-link>
              <router-link v-if="isAuthenticated" class="btn btn-secondary" to="/profile">Profile</router-link>
            </template>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import {defineComponent} from 'vue';
import logo from '/public/logo.png';
import {mapGetters, mapState} from "vuex";

export default defineComponent({
  name: 'NavBar',
  data() {
    return {
      isCollapsed: true,
      logo: logo,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapState('auth', ['loading']),
    API_Docs_URL: function () {
      return process.env.VUE_APP_BACKEND_BASE_URL + 'docs/';
    },
  },
  methods: {
    goto_home() {
      this.$router.push('/');
    },
  },
  watch: {
    $route() {
      this.isCollapsed = true;
    },
  },
});
</script>

<style scoped>

.navbar {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

.website-name {
  font-size: 1.5rem;
  font-weight: bold;
}

.logo {
  height: 40px;
  margin-right: 0.5rem;
}

.navbar-nav {
  margin-left: auto;
  margin-right: auto;
}

.navbar-nav .nav-link {
  color: #333333;
  font-size: 1rem;
  margin-left: 1.5rem;
  transition: color 0.3s;
}

.navbar-nav .nav-link:hover {
  color: #007bff;
}

.navbar-nav .nav-link.router-link-exact-active {
  color: #007bff;
  font-weight: bold;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-buttons {
  margin-left: 1.5rem;
}

@media (max-width: 768px) {
  .navbar-nav {
    margin-left: 0;
    margin-right: 0;
    margin-top: 1rem;
  }

  .navbar-buttons {
    margin-left: 0;
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .btn {
    display: block;
    width: auto;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

@media (max-width: 576px) {
  .navbar-buttons {
    flex-direction: column;
    align-items: center;
  }

  .btn {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s;
}

.btn-primary {
  background-color: #007bff;
  color: #ffffff;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: #ffffff;
  border: none;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>