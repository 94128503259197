// axios.js
import axios from 'axios';
import store from './store';

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_BASE_URL,
    withCredentials: true,
});

apiClient.interceptors.request.use(
    (config) => {
        const token = store.state.auth.accessToken;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default apiClient;