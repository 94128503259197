<template>
  <footer class="footer">
    <div class="container">
      <figure class="text-center text-body-secondary">
        <blockquote class="blockquote">
          <p>Made with ❤️ in San Francisco, California, US</p>
        </blockquote>
        <figcaption class="blockquote-footer">
          A <a href="https://kyte.dev">kyte.dev</a> project
        </figcaption>
        <small v-if="commitHash" class="text-muted">
          Build: {{ commitHash.slice(0, 7) }}
        </small>
      </figure>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      commitHash: process.env.VUE_APP_COMMIT_HASH || null
    }
  }
}
</script>

<style scoped>
.footer {
  text-align: center;
  padding: 1rem 0;
}

.blockquote {
  font-size: 1rem;
}
</style>
