<template>
  <div class="auth-page">
    <div class="auth-container">
      <h2>Password Reset</h2>
      <form @submit.prevent="handleSubmit" v-if="!emailSubmitted">
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="email" required :disabled="loading"/>
        </div>
        <button type="submit" :disabled="loading" class="auth-button">
          {{ loading ? 'Sending...' : 'Send Reset Code' }}
        </button>
      </form>
      <form @submit.prevent="resetPasswordHandler" v-if="emailSubmitted">
        <div class="form-group">
          <p>A password reset code has been sent to {{ email }}</p>
          <label for="resetCode">Reset Code:</label>
          <input type="text" id="resetCode" v-model="resetCode" required :disabled="loading"/>
        </div>
        <div class="form-group">
          <label for="newPassword">New Password:</label>
          <input type="password" id="newPassword" v-model="newPassword" required :disabled="loading"/>
        </div>
        <div class="form-group">
          <label for="confirmPassword">Confirm Password:</label>
          <input type="password" id="confirmPassword" v-model="confirmPassword" required :disabled="loading"/>
        </div>
        <button type="submit" :disabled="loading" class="auth-button">
          {{ loading ? 'Resetting...' : 'Reset Password' }}
        </button>
      </form>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      <div v-if="showSuccessMessage" class="success-message">
        <div class="success-icon">
          <i class="bi bi-check-circle-fill"></i>
        </div>
        <div class="success-text">
          Password reset successfully!
        </div>
        <div class="redirect-message">
          Redirecting to your profile in {{ redirectCountdown }} seconds...
        </div>
        <div class="redirect-progress">
          <div class="progress-bar" role="progressbar" :style="{ width: redirectProgress + '%' }"
               :aria-valuenow="redirectProgress" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import '@/assets/auth-styles.css'

export default {
  data() {
    return {
      email: '',
      resetCode: '',
      newPassword: '',
      confirmPassword: '',
      emailSubmitted: false,
      showSuccessMessage: false,
      redirectCountdown: 3,
      redirectProgress: 0,
      redirectTimer: null,
    };
  }, computed: {
    ...mapGetters('auth', ['isLoading', 'getErrorMessage']),
    loading() {
      return this.isLoading;
    }, errorMessage() {
      return this.getErrorMessage;
    },
  }, methods: {
    ...mapActions('auth', ['requestPasswordReset', 'resetPassword']),
    async handleSubmit() {
      try {
        await this.requestPasswordReset(this.email);
        this.emailSubmitted = true;
      } catch (error) {
        console.error('Failed to send reset code:', error);
      }
    },
    async resetPasswordHandler() {
      if (this.newPassword !== this.confirmPassword) {
        this.errorMessage = 'Passwords do not match';
        return;
      }
      try {
        await this.resetPassword({
          newPassword: this.newPassword,
          resetMode: "magic",
          magicToken: this.resetCode,
          email: this.email
        });
        this.showSuccessMessage = true;
        this.startRedirectCountdown();
      } catch (error) {
        console.error('Failed to reset password:', error);
      }
    },
    startRedirectCountdown() {
      this.redirectCountdown = 3;
      this.redirectProgress = 0;
      this.redirectTimer = setInterval(() => {
        this.redirectCountdown--;
        this.redirectProgress = (3 - this.redirectCountdown) * 33.33;
        if (this.redirectCountdown <= 0) {
          clearInterval(this.redirectTimer);
          this.$router.push({name: 'Profile'});
        }
      }, 1000);
    },
  }, beforeUnmount() {
    clearInterval(this.redirectTimer);
  },
};
</script>

<style scoped>
</style>