<template>
  <div class="not-found">
    <h1>Oops! 404 - Page Not Found</h1>
    <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>

    <div class="container">
      <div class="character" v-for="(character, index) in characters" :key="index"
           :style="{ animationDelay: `${index * 0.2}s` }"></div>
    </div>

    <router-link to="/" class="btn">Go Back to Homepage</router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      characters: [1],
    };
  },
};
</script>

<style scoped>
.not-found {
  //background-color: #f7f7f7;
  font-family: Arial, sans-serif;
  text-align: center;
  padding-top: 50px;
  height: 100vh;
}

h1 {
  font-size: 48px;
  color: #333;
  margin-bottom: 20px;
}

p {
  font-size: 24px;
  color: #666;
  margin-bottom: 40px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.character {
  position: relative;
  width: 200px;
  height: 200px;
  background-color: #0fb0cc;
  border-radius: 50%;
  margin: 0 20px;
  animation: bounce 1s ease-in-out infinite;
}

.character::before {
  content: '';
  position: absolute;
  top: 40px;
  left: 60px;
  width: 30px;
  height: 30px;
  background-color: #333;
  border-radius: 50%;
  box-shadow: 50px 0 0 #333;
}

.character::after {
  content: '';
  position: absolute;
  top: 90px;
  left: 75px;
  width: 50px;
  height: 20px;
  background-color: #333;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateY(-20px);
  }
}

.btn {
  display: inline-block;
  padding: 12px 24px;
  background-color: #007bff;
  color: #333;
  font-size: 18px;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #0056b3;
}
</style>