import {
    confirmResetPassword,
    confirmSignIn,
    confirmSignUp,
    fetchAuthSession,
    getCurrentUser,
    resendSignUpCode,
    resetPassword as resetPasswordAPI,
    signIn,
    signOut,
    signUp,
    updatePassword,
    decodeJWT
} from 'aws-amplify/auth';
/* import jwtDecode from 'jwt-decode'; */

// Create user object from Cognito user object
function createUserObject(user) {
    console.log('createUserObject: ', user);
    return {
        username: user.username,
        userId: user.userId,
        signInDetails: {
            loginId: user.signInDetails.loginId,
            authFlowType: user.signInDetails.authFlowType,
        },
    };
}

export default {
    namespaced: true,
    state: {
        user: null,
        idToken: null,
        accessToken: null,
        decodedIdToken: null,
        decodedAccessToken: null,
        error: null,
        loading: true,
        email: null,
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
        },
        SET_ID_TOKEN(state, idToken) {
            state.idToken = idToken;
            state.decodedIdToken = idToken ? decodeJWT(idToken).payload : null;
        },
        SET_ACCESS_TOKEN(state, accessToken) {
            state.accessToken = accessToken;
            state.decodedAccessToken = accessToken ? decodeJWT(accessToken).payload : null;
        },
        SET_ERROR(state, error) {
            state.error = error;
        },
        SET_LOADING(state, loading) {
            console.log('SET_LOADING: ', loading)
            state.loading = loading;
        },
        CLEAR_ERROR(state) {
            state.error = null;
        },
        SET_TEMP_EMAIL_FOR_SIGNUP(state, email) {
            state.email = email;
        }
    },
    actions: {
        async loginUser({commit, dispatch}, {email, password}) {
            commit('SET_LOADING', true);
            commit('CLEAR_ERROR');
            try {
                const {isSignedIn, nextStep} = await signIn({
                    username: email,
                    password,
                });
                if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
                    console.log('loginUser: nextStep', nextStep);
                    return nextStep.signInStep;
                }
                if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
                    console.log('loginUser: nextStep', nextStep);
                    commit('SET_TEMP_EMAIL_FOR_SIGNUP', email);
                    return nextStep.signInStep;
                }
                if (isSignedIn) {
                    console.log('loginUser: signed in');
                    const user = await getCurrentUser();
                    await dispatch('fetchSession', user);
                    return true;
                }
                throw new Error('Login failed');
            } catch (error) {
                console.error('loginUser error:', error);
                commit('SET_ERROR', error.message);
                throw error;
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async logout({commit}) {
            commit('SET_LOADING', true);
            commit('CLEAR_ERROR');
            try {
                await signOut();
                commit('SET_USER', null);
                commit('SET_ID_TOKEN', null);
                commit('SET_ACCESS_TOKEN', null);
            } catch (error) {
                console.error('logout error:', error);
                commit('SET_ERROR', error.message);
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async fetchSession({commit, dispatch}, user = null) {
            console.log('fetchSession start');
            commit('SET_LOADING', true);
            commit('CLEAR_ERROR');
            try {
                // Add artificial delay
                await new Promise(resolve => setTimeout(resolve, 3000)); // 3 second delay

                if (user) {
                    const userInfo = createUserObject(user);
                    commit('SET_USER', userInfo);
                    await dispatch('fetchAuthKeys');
                    console.log('fetchSession: user found');
                } else {
                    console.log('fetchSession: no user found');
                    commit('SET_USER', null);
                    commit('SET_ID_TOKEN', null);
                    commit('SET_ACCESS_TOKEN', null);
                }
            } catch (error) {
                console.error('fetchSession error:', error);
                commit('SET_ERROR', error.message);
            } finally {
                console.log('fetchSession end');
                commit('SET_LOADING', false);
            }
        },
        async fetchAuthKeys({commit}) {
            commit('SET_LOADING', true);
            commit('CLEAR_ERROR');
            try {
                const session = await fetchAuthSession();
                const idToken = session.tokens.idToken.toString();
                const accessToken = session.tokens.accessToken.toString();
                console.log('fetchAuthKeys: ', session);
                commit('SET_ID_TOKEN', idToken);
                commit('SET_ACCESS_TOKEN', accessToken);
            } catch (error) {
                console.error('fetchAuthKeys error:', error);
                commit('SET_ERROR', error.message);
                commit('SET_ID_TOKEN', null);
                commit('SET_ACCESS_TOKEN', null);
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async signupUser({commit}, {email, password, confirmPassword, agreeToTerms}) {
            commit('SET_LOADING', true);
            commit('CLEAR_ERROR');
            try {
                if (password !== confirmPassword) {
                    throw new Error('Password and Confirm Password do not match');
                }
                if (!agreeToTerms) {
                    throw new Error('You must agree to the terms and conditions');
                }

                let signup_result = await signUp({
                    username: email,
                    password: password,
                    options: {
                        attributes: {
                            email: email,
                        },
                    },
                });

                console.log('signupUser: ', signup_result);

                // Automatically log in the user after successful signup
                //await dispatch('loginUser', { email, password });
                if (signup_result.nextStep.signUpStep === "CONFIRM_SIGN_UP") {
                    commit('SET_TEMP_EMAIL_FOR_SIGNUP', email);
                    return "CONFIRM_SIGN_UP";
                }
                return true;
            } catch (error) {
                console.error('signupUser error:', error);
                commit('SET_ERROR', error.message);
                throw error;
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async changePassword({commit}, {oldPassword, newPassword}) {
            commit('SET_LOADING', true);
            commit('CLEAR_ERROR');
            try {
                const result = await updatePassword({oldPassword, newPassword});
                console.log('changePassword: ', result);
                return {success: true};
            } catch (error) {
                console.error('changePassword error:', error);
                commit('SET_ERROR', error.message);
                throw error;
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async requestPasswordReset({commit}, email) {
            commit('SET_LOADING', true);
            commit('CLEAR_ERROR');
            try {
                const result = await resetPasswordAPI({username: email});
                console.log('requestPasswordReset: ', result);
                return true;
            } catch (error) {
                console.error('requestPasswordReset error:', error);
                commit('SET_ERROR', error.message);
                throw error;
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async resetPassword({commit}, {newPassword, resetMode, magicToken, email}) {
            commit('SET_LOADING', true);
            commit('CLEAR_ERROR');
            try {
                let result;
                if (resetMode === 'forced') {
                    // Handle forced password reset during login
                    console.log('forced password reset called');
                    result = await confirmSignIn({challengeResponse: newPassword});
                    console.log('resetPassword: confirmSignIn', result);
                    return true;
                } else if (resetMode === 'magic') {
                    console.log('resetPassword: magicToken', magicToken);
                    result = await confirmResetPassword({
                        username: email,
                        confirmationCode: magicToken,
                        newPassword: newPassword
                    });
                    console.log('resetPassword: confirmResetPassword', result);
                    return true;
                }
            } catch (error) {
                console.error('resetPassword error:', error);
                commit('SET_ERROR', error.message);
                throw error;
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async confirmSignUp({commit}, {email, code}) {
            commit('CLEAR_ERROR');
            try {
                const confirmSignUp_result = await confirmSignUp(
                    {username: email, confirmationCode: code}
                );
                console.log('confirmSignUp: ', confirmSignUp_result);
                return true;
            } catch (error) {
                console.error('confirmSignUp error:', error);
                commit('SET_ERROR', error.message);
                throw error;
            } finally {
                // commit('SET_LOADING', false);
            }
        },
        async resendSignUpCode({commit}, email) {
            commit('CLEAR_ERROR');
            try {
                const resendSignUpCode_result = await resendSignUpCode({username: email});
                console.log('resendSignUpCode: ', resendSignUpCode_result);
                return true;
            } catch (error) {
                console.error('resendSignUpCode error:', error);
                commit('SET_ERROR', error.message);
                throw error;
            } finally {
                // commit('SET_LOADING', false);
            }
        }
    },
    getters: {
        isAuthenticated(state) {
            const isAuthenticated = !!state.user;
            console.log('isAuthenticated: ', isAuthenticated);
            return isAuthenticated;
        },
        getErrorMessage(state) {
            return state.error;
        },
        isLoading(state) {
            return state.loading;
        },
        getEmail(state) {
            return state.email;
        },
    },
};